import router from "@/router";

const clientModule = {
    state: {
        participantId: null,
        token: null,
    },
    getters: {
        participantId(state) {
            return state.participantId
        },
        isAuthenticated(state) {
            return state.token != null
        },
        suggestedParticipant(_, _2, rootState) {
            const suggestedParticipantId = localStorage.getItem("participantId")
            if (!suggestedParticipantId) return null
            return rootState.participants.participants.find(p => p.id == suggestedParticipantId) ?? null
        },
        parsedToken(state) {
            if (!state.token) return
            const parts = state.token.split('.');
            if (parts.length !== 3) return

            const [header, payload] = parts;
            const decodedHeader = atob(header);
            const decodedPayload = atob(payload);

            const parsedHeader = JSON.parse(decodedHeader);
            const parsedPayload = JSON.parse(decodedPayload);

            return { header: parsedHeader, payload: parsedPayload };
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token
            if (token) localStorage.setItem("token", token)
            else localStorage.removeItem("token")
        },
        setParticipantId(state, id) {
            state.participantId = id
            if (id) localStorage.setItem("participantId", id)
        },
    },
    actions: {
        async authenticate({ commit, dispatch }, { name, password }) {
            const response = await fetch(process.env.VUE_APP_BACKEND_URL + "/auth", {
                body: JSON.stringify({
                    name: name, password: password
                }),
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }
            })
            const data = await response.json()
            if (!data?.token) throw new Error(data?.message ?? "Ein Fehler ist aufgetreten.")
            commit("setToken", data.token)
            dispatch("setParticipantId")
        },
        async register({ rootGetters, dispatch }, { name, password }) {
            const response = await fetch(process.env.VUE_APP_BACKEND_URL + "/participants.json", {
                body: JSON.stringify({
                    name: name.replaceAll("ß", ""),
                    password: password,
                    tournament: process.env.VUE_APP_BACKEND_PREFIX + "/tournaments/" + rootGetters["tournament/selectedTournament"].id
                }),
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }
            })
            await dispatch("participants/loadParticipants", { forceReload: true }, { root: true })
            if (response.error) throw new Error(response.error)
        },
        logout({ commit }) {
            commit("setToken", null)
            commit("setParticipantId", null)
            commit("tournament/setTournament", null, { root: true })

            router.replace({ name: "login" }).catch(() => { })

            commit("activities/clearLastFetched", null, { root: true })
            commit("participants/clearLastFetched", null, { root: true })
            commit("sports/clearLastFetched", null, { root: true })
            commit("tournament/clearLastFetched", null, { root: true })
        },
        setParticipantId({ state, getters, rootState, commit }) {
            const id = state.token ? rootState.participants.participants.find(
                p => p.name == getters.parsedToken.payload.username
            )?.id ?? null : null
            commit("setParticipantId", id)
        },
        async initializeUnauthenticated({ dispatch, commit }) {
            if (localStorage.getItem("token")) {
                commit("setToken", localStorage.getItem("token"))
            }
            await dispatch("participants/loadParticipants", { forceReload: false }, { root: true })
            await dispatch("tournament/setSelectedTournament", null, { root: true })
            dispatch("setParticipantId")
        }
    },
    namespaced: true
}

export default clientModule