<template>
  <div>
    <v-row align="end">
      <v-col class="text-center" cols="4">
        <user-icon
          :userId="participant.id"
          size="80"
          class="current-user-icon"
        />
      </v-col>
      <v-col class="text-left">
        <v-label class="text-button" style="font-size: 1.5em !important">{{
          participant.name
        }}</v-label>
        <v-divider class="mt-2 mr-5" />
      </v-col>
    </v-row>
    <v-row
      align="start"
      class="mt-0 pt-0"
      style="position: relative; bottom: 0.8em"
      v-if="isCurrentParticipant && !isLoading"
    >
      <v-col
        class="text-center"
        cols="4"
        @click="selectingProfilePicture = true"
      >
        <v-label class="text-button" style="font-size: 0.6em !important"
          ><v-icon size="12" class="mr-1 mb-1">mdi-pencil</v-icon
          >Ändern</v-label
        >
      </v-col>
      <v-col class="text-left"> </v-col>
    </v-row>

    <div v-if="!isLoading">

    <v-row dense class="mt-2 pt-2">
      <v-col class="text-right" cols="2"><v-icon>mdi-trophy</v-icon> </v-col
      ><v-col cols="10" class="text-left">
        <v-label style="font-size: 0.9em !important" class="mr-2"
          >Aktivitätspunkte gesamt:</v-label
        ><span class="font-weight-bold">{{
          participant.totalActivityPointsEarned ?? 0
        }}</span>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col class="text-right" cols="2"><v-icon>mdi-trophy</v-icon> </v-col
      ><v-col cols="10" class="text-left">
        <v-label style="font-size: 0.9em !important" class="mr-2"
          >Aktivitätspunkte diese Woche:</v-label
        ><span class="font-weight-bold">{{
          participant.weekActivityPoints ?? 0
        }}</span>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col class="text-right" cols="2"><v-icon>mdi-clock</v-icon> </v-col
      ><v-col cols="10" class="text-left">
        <v-label style="font-size: 0.9em !important" class="mr-2"
          >Gesamtzeit Sport:</v-label
        ><span class="font-weight-bold"
          >{{ toTimeString(participant.totalActivityTime) }} h</span
        >
      </v-col>
    </v-row>

    <v-row dense v-if="favouriteSport" align="center">
      <v-col class="text-right" cols="2"><v-icon>mdi-heart</v-icon> </v-col
      ><v-col cols="10" class="text-left">
        <v-label style="font-size: 0.9em !important" class="mr-2"
          >Lieblingssport:</v-label
        ><span class="font-weight-bold">{{ favouriteSport.name }}</span>
      </v-col>
    </v-row>

    <base-section style="margin-top: 1.5em !important" title="Abzeichen">

    <v-row justify="center" class="mx-2 my-2">
      <v-col cols="10" class="pa-1 ma-0">
        <streak-view :participant="participant" />
      </v-col>
    </v-row>
      <badge-list :participant="participant"
    /></base-section>
    <base-section title="Statistik" v-if="isCurrentParticipant">
      <sport-stats
    /></base-section>
    </div>

    <div v-else>
      <v-row class="my-7">
        <v-col class="text-center">
          <v-progress-circular indeterminate :size="40" />
        </v-col>
      </v-row>
    </div>

    <base-dialog
      :open="selectingProfilePicture"
      title="Neues Profilbild"
      @close="closeDialog"
    >
      <profile-picture-select @close="closeDialog" />
    </base-dialog>
  </div>
</template>

<script>
import { toHoursAndMinutesString } from "@/helper/displayHelper";
import { mapActions, mapGetters } from "vuex";
import BaseDialog from "../BaseDialog.vue";
import BaseSection from "../BaseSection.vue";
import ProfilePictureSelect from "./ProfilePictureSelect.vue";
import SportStats from "./SportStats.vue";
import BadgeList from "./BadgeList.vue";
import StreakView from "./StreakView.vue";
export default {
  components: {
    UserIcon: () => import("../shared/UserIcon.vue"),
    BaseSection,
    ProfilePictureSelect,
    BaseDialog,
    SportStats,
    BadgeList,
    StreakView,
  },
  name: "participant-overview",
  props: {
    participant: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      selectingProfilePicture: false,
      isLoading: true
    };
  },
  async created() {
    this.isLoading = true
    await this.loadParticipant(this.participant.id)
    this.isLoading = false
  },
  computed: {
    ...mapGetters("participants", ["currentParticipant"]),
    ...mapGetters("sports", ["sportById"]),
    isCurrentParticipant() {
      return this.currentParticipant.id == this.participant.id;
    },
    favouriteSport() {
      if (!this.participant.favouriteSport) {
        return null;
      }
      return this.sportById(this.participant.favouriteSport);
    },
  },
  methods: {
    ...mapActions("participants", ["loadParticipant"]),
        closeDialog() {
      this.selectingProfilePicture = false;
    },
    toTimeString(minutes) {
      return toHoursAndMinutesString(minutes);
    },
  },
};
</script>

<style>
</style>